@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";

.card-bg {
  background-color: #ffffff;
}

.chat-bot-box-shadow {
  box-shadow: 0 3px 20px #969696;
  border-radius: 10px;
}

body {
  --primary-color: #3d6e9c;
  --surface-50: white;
}
